.Home {
  margin-top: 6rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

/* Media query for tablet, mobile screens */
@media (max-width: 900px) {
  .Home {
    margin-top: 4.5rem;
    margin-left: 0rem;
    margin-right: 0rem;
  }

  h1 {
    font-size: 30px;
    margin: auto 1rem;
  }
}
