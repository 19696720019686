.signin-container {
    max-width: 400px;
    margin: 0 auto;
    margin-top: 8rem;
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  .signin-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    width: 100%;
    margin-bottom: 15px;
  }
  
  label {
    font-weight: bold;
  }
  
  input[type="email"],
  input[type="password"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 95.6%;
  }
  
  .signin-button {
    padding: 8px 16px;
    background-color: #1977f3;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;
  }
  
  .signin-button:hover {
    background-color: #1871e5;
  }
  .signup-text-link  {
    text-decoration: none;
  }

  .signup-text {
    text-align: center;
  }

  .signup-text-link:visited, .password-reset-link:visited {
    color: #1871e5;
  }
  .signup-text-link:hover {
    color: #1564ca;
  }

  .password-reset-link {
    text-decoration: none;
    margin-left: auto;
  }
  