.forgot-password-container {
    max-width: 400px;
    margin: 0 auto;
    margin-top: 8rem;
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .forgot-password-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    font-weight: bold;
  }
  
  input[type="email"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .reset-password-button {
    padding: 8px 16px;
    background-color: #1977f3;
    color: #fff;
    /* margin-top: 15px; */
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .reset-password-button:hover {
    background-color: #1871e5;
  }
  