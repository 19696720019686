.carousel {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slides-container {
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
}

.slide {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.slide.active {
  opacity: 1;
}

.dots-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  transform: translateX(-50%);
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  border-width: 1.5px;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: yellow;
}
