.App-header {
  background-color: #282c34;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 1;
  height: 65px;
}

.header-links {
  display: flex;
  width: 100%;
}
.dropdown-content {
  margin-top: 20px;
}
.App-logo {
  width: 120px;
  object-fit: contain;
  margin: 1rem;
}

.header-link {
  padding: 25px;
  position: relative;
  text-decoration: none;
  color: white;
}

.right {
  margin-left: auto;
}

.sign-in-button {
  border-radius: 34px;
  padding: 10px 20px;
  margin: 0.7rem;
  margin-left: auto;
  color: white;
  background-color: #1977f3;
  font-size: 18px;
}

.sign-in-button:hover {
  background-color: #1871e5;
}
.sign-in-link {
  text-decoration: none;
  color: #fff;
}

.dropdown-menu,
.App-logo-link-mobile,
.close-button,
.menu-header {
  display: none;
}

/* Media query for mobile screens */
@media (max-width: 770px) {
  .App-header {
    background-color: #282c34;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .App-logo {
    width: 140px;
  }

  .App-logo-link-mobile {
    -webkit-tap-highlight-color: transparent;
    display: flex;
  }
  .App-logo-link {
    display: none;
  }
  .header-links {
    position: relative;
    margin: 0px;
  }

  .header-link {
    padding: 10px;
    position: relative;
    text-decoration: none;
    color: black;
  }

  .sign-in-button {
    margin: 1rem;
    margin-left: auto;
    -webkit-tap-highlight-color: transparent;
  }

  .dropdown-menu {
    display: contents;
    padding: 5px;
  }

  .dropdown-content {
    margin-top: 0;
    display: none;
    border-radius: 4px;
    text-align: left;
  }

  .dropdown-content.open {
    display: block;
  }

  .header-links.open .dropdown-content {
    display: block;
  }

  .header-link {
    display: block;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .menu-header {
    display: block;
    background-color: #282c34;
    margin: 0;
    padding: 16px;
  }

  .dropdown-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-right: 10px;
    width: 35px;
    cursor: pointer;
  }

  .dropdown-icon span {
    width: 100%;
    background-color: #fff;
    padding: 2px;
    margin: 4px 0;
    transition: background-color 0.3s ease-in-out;
  }

  .left-column-menu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fdfdfc;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .left-column-menu.open {
    transform: translateX(0);
  }

  .close-button {
    display: none;
  }

  .left-column-menu.open .close-button {
    display: block;
    font-size: 30px;
    position: absolute;
    top: 10px;
    right: 30px;
    cursor: pointer;
  }
}
