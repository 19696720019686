.about-us-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100vh;
  background-image: url("../../public/about.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  margin: 7rem 2rem;
  margin-bottom: 2rem;
  color: white;
}

.title {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.description {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.info-box {
  /* background-color: rgba(255, 255, 255, 0.7); */
  padding: 2rem;
  border-radius: 10px;
  text-align: left;
  box-shadow: 4px 4px 4px rgba(255, 255, 255, 0.6),
    -4px -4px 4px rgba(255, 255, 255, 0.6);
}

.info-details {
  color: white;
  font-size: 1.2rem;
  line-height: 1.5;
}

.content-container {
  padding: 1rem;
}

.mobile-view {
  display: none;
}

.our-mission {
  margin: 2rem;
  display: flex;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.left-column {
  flex: 5;
  padding: 20px;

  text-align: justify;
  padding: 1.5rem;
  line-height: 1.6;
}

.right-column {
  flex: 4;
  display: flex;
}

.our-mission-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.text {
  font-size: 16px;
}

.our-mission-image {
  width: 90%;
  height: auto;
  margin-left: auto;
  border-radius: 10px;
  margin-top: 1rem;
}

.two-column-cards-container {
  display: flex;
}

.card {
  display: block;
  border-radius: 8px;
  padding: 1rem 2rem;
  margin: 16px;
  flex: 1;
  box-shadow: 0 16px 18px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 24px;
  margin-bottom: 8px;
}

.card-description {
  font-size: 16px;
  text-align: left;
}

.circle {
  display: inline-block;
  background-color: #54ddff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: white;
  font-weight: bold;
}



/* Media query for tablet, mobile screens */
@media (max-width: 900px) {
  .info-box,
  .title {
    display: none;
  }

  .about-us-container {
    background-size: unset;
  }
  .mobile-view {
    display: contents;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .about-us-mobile {
    margin: 2rem;
    display: flex;
    text-align: justify;
    padding: 1.5rem;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    line-height: 1.6;
  }

  .title-mobile {
    margin: 2rem;
    color: black;
  }

  .our-mission {
    display: inline;
  }

  .our-mission-image {
    margin: 0 auto;
  }

  .two-column-cards-container {
    display: block;
  }
  
}
