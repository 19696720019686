.tour-card {
  display: flex;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
}

.image-container {
  display: flex;
  flex: 0 0 auto;
  margin-right: 16px;
  align-items: center;
  justify-content: center;
}

.tour-image {
  width: 350px;
  height: auto;
  border-radius: 8px;
}

.card-content {
  flex: 1;
}

.tour-card-title {
  font-size: 2.5rem;
  font-weight: bold;
  white-space: normal;
  word-wrap: break-word;
}

.tour-card-subtitle {
  font-size: 16px;
  color: #555555;
}

.price-rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.price {
  font-size: 16px;
  font-weight: bold;
}

.rating {
  font-size: 14px;
  color: #888888;
}

@media (max-width: 1000px) {

  .image-container {
    margin-right: 0px;
  }

  .tour-card .image-container {
    width: 100%;
  }

  .tour-card-title {
    font-size: 2rem;
  }
  .tour-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1rem 2rem;
  }
}

@media (max-width: 800px) {
  .tour-image {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
  }

  .tour-card-title {
    margin: 1rem 0rem;
  }
  .tour-card-subtitle {
    font-size: 12px;
  }
}
