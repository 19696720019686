.Contact {
  margin-top: 5rem;
  justify-content: center;
  align-items: center;
}

.center {
  margin-left: 0;
  text-align: center;
  background-image: url("../../public/background1.png");
  background-size: 100%;
  background-repeat: no-repeat;
  color: white;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
